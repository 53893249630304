<template>
    <div class="applicationContent">
        <div class="applicationHead">更多功能</div>
        <div>
            <div v-if="application.searchList.length">
                <div class="applicationTitle">服务商查询</div>
                <ul class="applicationAppGroup">
                    <li v-for="(app, index) in application.searchList" :key="index" @click="applicationClick(app)">
                        <div class="applicationSvg" :style="{ background: 'var(--themeColor)' }">
                            <i-icon color="#fff" :name="app.icon" size="30" />
                        </div>
                        <p>{{ app.name }}</p>
                    </li>
                </ul>
            </div>

            <div v-if="application.serviceList.length">
                <div class="applicationTitle">扩展功能</div>
                <ul class="applicationAppGroup">
                    <li v-for="(app, index) in application.serviceList" :key="index" @click="applicationClick(app)">
                        <div class="applicationSvg" :style="{ background: 'var(--themeColor)' }">
                            <i-icon color="#fff" :name="app.icon" size="30" />
                        </div>
                        <p>{{ app.name }}</p>
                    </li>
                </ul>
            </div>
            <div v-if="application.orderList.length">
                <div class="applicationTitle">订单查询</div>
                <ul class="applicationAppGroup">
                    <li v-for="(app, index) in application.orderList" :key="index" @click="applicationClick(app)">
                        <div class="applicationSvg" :style="{ background: 'var(--themeColor)' }">
                            <i-icon color="#fff" :name="app.icon" size="30" />
                        </div>
                        <p>{{ app.name }}</p>
                    </li>
                </ul>
            </div>
        </div>
        <i-skeleton v-if="isFirst" :skList="skList" />
        <emptyBox v-if="!application.orderList.length && !application.searchList.length && !application.serviceList.length && !isFirst" />
    </div>
</template>

<script>
import emptyBox from "@/components/iEmpty";
import publicFun from "../utils/publicFun";
import {SmartStorage} from "smart-core-util";

export default {
    name: "Application",
    components: {
        emptyBox,
    },
    data() {
        return {
            application: {
                orderList: [],
                searchList: [],
                serviceList: [],
            },
            isFirst: true,
            skList: [
                {
                    width: "2rem",
                    height: ".25rem",
                    inline: true,
                    row: 2,
                },
                {
                    width: "20%",
                    height: ".5rem",
                    mr: "5%",
                    mt: ".2rem",
                },
                {
                    width: "20%",
                    height: ".5rem",
                    mr: "5%",
                    mt: ".2rem",
                },
                {
                    width: "20%",
                    height: ".5rem",
                    mr: "5%",
                    mt: ".2rem",
                },
                {
                    width: "20%",
                    height: ".5rem",
                    mr: "5%",
                    mt: ".2rem",
                },
                {
                    width: "20%",
                    height: ".5rem",
                    mr: "5%",
                },
                {
                    width: "20%",
                    height: ".5rem",
                    mr: "5%",
                },
                {
                    width: "20%",
                    height: ".5rem",
                    mr: "5%",
                },
                {
                    width: "20%",
                    height: ".5rem",
                    mr: "5%",
                },
            ],
        };
    },
    created() {
        this.getApp();
    },
    methods: {
        getApp() {
            let param = {
                collection: "cfg-ievent-application",
                filter: {
                    tenantCode: this.$cookie.get("tenant") || this.$smartStorage.get("tenant"),
                    tenantId: this.$cookie.get("tenant_id") || "",
                },
                projection: {
                    content: 1,
                },
            };
            this.$service.GetSettings(param).then((res) => {
                this.isFirst = false;
                if (res.success) {
                    this.application = {
                        searchList: res?.content?.content?.searchList || [],
                        serviceList: res?.content?.content?.serviceList || [],
                        orderList: res?.content?.content?.orderList || [],
                    };
                }
            });
        },
        applicationClick(item) {
          console.log(item);
          this[item.type](item);
        },
        // 路由跳转
        routerJump(item){
            this.$router.push({
              path: item.path + '?' + item.params + '&config=' + JSON.stringify(item.config) + '&' + 'name=' + item.name
            })
            if(item.name === '会务支持'){ // 特别重新设置，住友会务支持列表是会议，在稍后跳转详情后需要使用到selectedEventType，以免被认为是差旅
                SmartStorage.set('selectedEventType', 'created');
            }
        },
        // 弹窗提示，点击确定跳转微站
        showDialog(item) {
            let params = {
                collection: "cfg-ishop-wiki",
                filter: {
                    tenantCode: this.$cookie.get("tenant") || this.$smartStorage.get("tenant"),
                    entryCode: "onlineRules",
                    tenant_id: this.$cookie.get("tenant_id") || "",
                },
            };
            console.log("====当前服务信息", item);
            // 华润则提示

            if (item.name == "餐厅推荐" && this.$cookie.get("tenant_id") == "1afb2744-d3c9-11eb-a9f0-ca43ba5bd41d") {
                this.$idialog
                    .confirm({
                        message: "餐厅推荐功能已升级， 用户可在订单中直接选择所需餐厅，无需推荐即可使用。",
                        confirmButtonText: "已知晓",
                        showCancelButton: false,
                        messageAlign: "left",
                    })
                return;
            }
            this.$service.GetSettings(params).then((res) => {
                if (res && res.success) {
                    this.$idialog
                        .confirm({
                            message: res.content?.content || "",
                            confirmButtonText: "已知晓",
                            showCancelButton: false,
                            messageAlign: "left",
                        })
                        .then(() => {
                            // on confirm
                            this.openWebApp(item);
                        });
                }
            });
        },
        // 打开内部站点
        openWebApp(item) {
            let appConf = {
                webAppAlias: item.urlKey,
                path: item.path,
            };
            this.$publicFun.openWebAppCate({}, appConf, item.noSaveCacheData);
        },
        // 打开外部站点
        outWebsite(webappAlias) {
            let params = {
                itemId: this.$publicFun.guid(),
            };
            this.$service[webappAlias.url](params).then((res) => {
                if (res.success) {
                    let url = res.content.url || res.content || "";
                    if (url) {
                        this.$publicFun.openDianPing(url);
                    }
                }
            });
        },
        //打开内部路径
        openPath(webappAlias) {
            this.$store.commit("setValue", {
                value: webappAlias,
                key: "webappAlias",
            });
            this.$smartStorage.set('webappAlias',webappAlias)
            // 要确保通用性
            this.$router.push({path: webappAlias.path, query: {type: webappAlias.value}});
        },
        //打开pop选择
        popChoose(webappAlias) {
          console.log(webappAlias);
          // if(webappAlias.name === '会务支持'){
          //   this.$router.push({
          //     name: 'ieventList',
          //     query: {
          //       type: 'callCar'
          //     }
          //   })
          // }
          publicFun.openPopChoose(this, webappAlias.type)
        },
        //先选择城市再跳转
        async cityJump(item) {
            console.log(item);
            if(item.urlKey == 'webapp-iwaimai'){ // 支持外卖企业码支付的客户跳转
                let mealRules = await this.getMealRules()
                if(mealRules.takeOutNew){
                    this.$router.push({
                        name: "moreTakeOut",
                        query: {
                            webAppAlias: item.urlKey,
                            path: item.path + "?" + item.params,
                        },
                    });
                    return
                }
            }
            this.$router.push({
                name: "applicationChooseCity",
                query: {
                    webAppAlias: item.urlKey,
                    path: item.path + "?" + item.params,
                },
            });
        },
        async getMealRules(){
            let params = {
                collection: "cfg-imeal-mealrules",
                useCaching: false,
                cachingKey: "",
                filter: {
                    tenant_id: this.$smartStorage.get("tenant_id") || this.$cookie.get("tenant_id"),
                },
            };
            return new Promise((resolve, reject) => {   
                this.$service.QuerySettings(params).then(res => {
                    if (res && res.success && res.content) {
                        console.log(res.content[0]);
                        resolve(res.content[0])
                    }else{
                        resolve({})
                    }
                }).catch(err => {
                    reject(false)
                })
            })
        },
    },
};
</script>

<style lang="less" scoped>
.applicationContent {
    height: 100%;
    box-sizing: border-box;
    padding: 0.15rem 0.2rem;

    .applicationHead {
        font-size: 0.24rem;
        color: #13161b;
        text-align: left;
    }

    .applicationTitle {
        color: #13161b;
        font-size: 0.16rem;
        text-align: left;
        margin-top: 0.2rem;
        margin-bottom: 0.2rem;
    }

    .applicationAppGroup {
        li {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            float: left;
            color: #909398;
            font-size: 0.12rem;
            width: 33.33%;
            margin-bottom: 0.15rem;

            .applicationSvg {
                width: 0.5rem;
                height: 0.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 0.14rem;
                position: relative;

                .icon {
                    position: absolute;
                    width: 0.3rem !important;
                    height: 0.3rem !important;
                    left: 0.11rem;
                    top: 0.11rem;
                }
            }
        }

        p {
            margin-top: 0.05rem;
            font-size: 0.12rem;
        }

        &:after {
            content: "";
            display: block;
            visibility: hidden;
            height: 0;
            clear: both;
        }
    }
}
</style>
